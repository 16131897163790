@use 'sass:math';

* {
  font-family: 'Nunito Sans', sans-serif !important;
}

// INPUT
.MuiFormControl-root {
  width: 100%;

  .MuiInputBase-root {
    .MuiInputBase-input {
      padding: 12px 14px;
    }
  }

  &.MuiTextField-root {
    .MuiInputBase-root {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);

      .MuiInputBase-inputSizeSmall {
        padding: 5px 8px !important;
      }
    }
  }
}
.form-input-wrapper {
  .MuiFormControl-root {
    &.MuiTextField-root {
      .MuiInputBase-root {
        font-size: 14px;
      }
    }
  }
}

.input-date,
.TimePicker {
  .MuiInputBase-root {
    &.MuiInputBase-formControl {
      padding-right: 15px;
      padding-left: 5px;

      .MuiInputBase-input {
        padding: 8px;
      }
    }
  }
}

.date-cutoff {
  .date-cutoff-picker {
    .MuiButtonBase-root {
      margin: 0 !important;
    }
  }
}

// BUTTON
.MuiButton-root {
  &.MuiButtonBase-root {
    padding: 8px 10px;
    font-size: 12px;
    border-radius: 8px;
    text-transform: none;
    box-shadow: none;

    &.card.card-box {
      & > .MuiTouchRipple-root {
        opacity: 0.3;
      }
    }

    &.Mui-disabled {
      opacity: 0.65;
    }

    &:active {
      box-shadow: none !important;
    }

    &:hover {
      box-shadow: none;
      .btn-icon-transition {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
      }
    }

    &:not(.MuiListItem-button):not(.MuiTab-root):not(:disabled):not(.MuiCheckbox-root):not(.MuiSwitch-switchBase):hover {
      transform: translateY(-2px);
    }

    &.btn-transition-none:hover {
      transform: translateY(0px) !important;
    }

    &.MuiButton-containedPrimary {
      background-color: #4b68ef;
      border: 1px solid #4b68ef;
      &.Mui-disabled {
        border: unset;
        background-color: #eeeff8;
      }
    }

    &.MuiButton-containedError {
      border: 1px solid #f83245;
      &.Mui-disabled {
        border: unset;
      }
    }
  }

  &.MuiButton-outlined {
    border: 1px solid #d4d5d6;
    color: #163a50;
  }
}

// PATHBREADCRUMBS
.breadcrumb-incard {
  .text-black {
    color: rgba(0, 0, 0, 0.87);
  }
}

// INPUTFIELD
// RemoveNotch
.no-notch {
  .MuiInputLabel-shrink {
    display: none;
  }
  .MuiInputBase-root {
    &.MuiInputBase-formControl {
      padding-top: 0;
      padding-bottom: 4px;
      &.MuiInputBase-sizeSmall {
        padding: 3px;
        padding-top: 2px;
        padding-bottom: 5px;

        .MuiInputAdornment-positionStart {
          padding-left: 8px;
        }
      }
    }
  }
  legend {
    display: none;
  }

  &.MuiInputBase-root {
    &.bg-secondary {
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;

      fieldset {
        position: unset;
        margin: 0;
        padding: 0;
        border: none;
      }
      select {
        padding: 6px 18px !important;
      }
    }
  }
  &.MuiTextField-root {
    width: 100%;
  }
}

// Customize chip on multiple select (Dropdown) -> primary
.multiple-dropdown__primary {
  .MuiAutocomplete-tag[role='button'] {
    background-color: $primary;
    color: $light;
    .MuiChip-deleteIcon {
      color: $light;
      &:hover {
        color: $white;
      }
    }
  }
}

.notch-dropdown {
  .MuiFormControl-root {
    &.MuiTextField-root {
      legend {
        display: none;
      }
      .MuiInputBase-root {
        border: none;
        padding-top: 0 !important;
        input {
          padding: 6.5px 5px;
        }
      }
    }
  }
}

.notch-dropdown2 {
  .MuiFormControl-root {
    &.MuiTextField-root {
      .MuiInputBase-root {
        border: none;
      }
    }
  }
}

.MuiDataGrid-cell {
  &.MuiDataGrid-cell--editing {
    box-shadow: none !important;
    .MuiAutocomplete-root {
      &.editCellInputMuiGrid {
        .MuiFormControl-root {
          .MuiInputBase-formControl {
            .MuiOutlinedInput-notchedOutline {
              border: none;
            }
          }
        }
      }
    }
  }
}

// POPUP DIALOG
.MuiPaper-root {
  &.MuiPaper-rounded {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .MuiDialogContent-root {
    .MuiGrid-root {
      .MuiIcon-root {
        width: auto;
        height: auto;
        overflow: auto;
      }
    }
  }
}

// MUI PAPER
.MuiPaper-elevation1 {
  box-shadow: none !important;
}

// .MuiTooltip-popper {
//   inset: 0px auto auto !important;
// }

.MuiPickersPopper-root {
  inset: 0px auto auto !important;
}

// .MuiButtonBase-root {
//   .MuiIconButton-edgeEnd {
//     right: '17px'
//   }
// }

//CHECKBOX
.MuiCheckbox-root {
  &.MuiCheckbox-root {
    color: #a7b5be;
  }
  &.Mui-checked {
    color: #4b68ef !important;
  }
  &.Mui-disabled {
    color: #ede8e8;
  }
}

// RESET JSS
.jss1 {
  min-height: auto !important;
  height: auto !important;
  font-weight: inherit !important;
}

.jss2 {
  font-size: inherit !important;
}

// NAV-WRAPPER
.nav-wrapper {
  margin-right: 22px;
  margin-left: 22px;
}

button {
  &.MuiButtonBase-root {
    &.MuiTab-root {
      opacity: 1;
      font-weight: 700;
      font-size: 14px;
      &[aria-selected='false'] {
        color: #163a50;
      }

      &.Mui-selected {
        &[aria-label='pick date'],
        &[aria-label='pick time'] {
          color: #fff !important;
        }
      }
    }
  }
}

// LEGENDS
.legends-label {
  justify-content: end;
  margin-bottom: 14px;
  margin-top: 20px;
  .legend-item {
    margin-right: 24px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }

    .label-color {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      margin-right: 12px;

      &.gray {
        background-color: #afb2b7;
      }
      &.blue {
        background-color: #4b68ef;
      }
      &.orange {
        background-color: #ff9800;
      }
      &.green {
        background-color: #4caf50;
      }
    }
  }
}

.see-chronology {
  border: 1px solid #afb2b7;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 6px 16px;
  color: #163a50;
  cursor: pointer;

  &:hover {
    background-color: #fdfeff;
  }
}

// BARCODE STOK
.barcode-stok {
  .MuiOutlinedInput-input {
    padding: 10px 12px;
  }

  fieldset {
    top: 1px;
  }

  button {
    &.scan-button {
      background: #f1f9ff;
      border-radius: 0;
      padding: 12px;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      border-left: 1px solid rgba(0, 0, 0, 0.23);
      color: #163a50;
    }
  }
}

.width-15 {
  width: 15%;
}

.width-85 {
  width: 85%;
}

.hover-none {
  font-size: 14px !important;
  background-color: #fff !important;
}
.hover-none:hover {
  background: none !important;
}

.table-stats {
  table {
    thead {
      th {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #163a50;
        border-right: 1px solid rgba(22, 58, 80, 0.13);
        padding: 0px 16px 5px 16px;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .circle-icon-table {
    width: 33px;
    height: 33px;
    background: #ffffff;
    padding: 8px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      th,
      td {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        border-right: 1px solid rgba(22, 58, 80, 0.13);
      }
    }
  }
}

.tab-invoice {
  &.MuiBox-root {
    .MuiTabs-root {
      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          background-color: #fff;

          .Mui-selected {
            border-bottom: 2px solid #4b68ef;
          }
        }
      }
    }
  }
}

.dropdown-profile {
  width: 307px;
  transform: translate(-4px, 27px) !important;
  -webkit-transform: translate(-4px, 27px) !important;
  -moz-transform: translate(-4px, 27px) !important;
  -ms-transform: translate(-4px, 27px) !important;
  -o-transform: translate(-4px, 27px) !important;
}

.MuiFormControlLabel-root {
  .MuiRadio-root {
    color: #afafaf;
    .MuiSvgIcon-root {
      height: 16px;
      width: 16px;
    }
  }
  .Mui-checked {
    color: #4b68ef !important;
  }
  .MuiFormControlLabel-label {
    font-size: 12px;
  }
}

.filter-text {
  .MuiInputBase-root {
    fieldset {
      position: unset;
      margin: 0;
      padding: 0;
      border: none;
    }
    select {
      padding: 5px 0;
    }
  }
  &.MuiTextField-root {
    width: 100%;
    border-bottom: 1px solid #d4d5d6;
  }
  .MuiInputBase-root {
    &.MuiInputBase-formControl {
      padding-top: 0;
      padding-bottom: 4px;
      &.MuiInputBase-sizeSmall {
        padding: 3px;
        padding-top: 2px;
        padding-bottom: 5px;

        .MuiInputAdornment-positionStart {
          padding-left: 8px;
        }
      }
    }
  }
  legend {
    display: none;
  }
}

.button-show-wrapper {
  padding-bottom: 7px;
  border-bottom: 1px solid #d4d5d6;
  .button-show-filter {
    background: #c2e3ff;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #163a50;
    width: 100%;
    border: none;
    padding: 5px;
  }
}

.detail-sync-price {
  .MuiTabs-root {
    .MuiTabs-fixed {
      .MuiTabs-flexContainer {
        justify-content: space-between;
        .MuiTab-root {
          flex: 1;
        }
      }

      .MuiTabs-indicator {
        height: 2px;
        min-height: 2px;
      }
    }
  }
}

.MuiTooltip-tooltipArrow {
  left: 44px !important;
}

.MuiAutocomplete-popper {
  left: 44px !important;
}

// Lite Grid
.border-bottom {
  border-bottom: 1px solid #f1f1f1;
}
.disabled-list {
  pointer-events: none;
  opacity: 0.4;
}

// SHADOW
.bottomShadow {
  border: 1px solid #ebebeb !important;
  box-shadow: 0px 8px 10px rgba(175, 137, 255, 0.15) !important;
}


.MuiFormControlLabel-label {
  font-size: 14px !important;
}