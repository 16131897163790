// Core

@import 'core';

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');

// Widgets

@import 'widgets';

// Components

@import 'components';

// Layout Components

@import 'layout-components';

// Layout Blueprints

@import 'layout-blueprints';

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.grid-cell-content:has(.border-cell) {
  border: '1px solid rgb(230, 233, 239)';
}

html {
  width: 100vw;
}

#shell {
  position: relative;
  z-index: 2;
}
#content {
  position: relative;
  z-index: 1;
  padding-top: 40px;
}

.gif_player {
  cursor: pointer;
  .play_button {
    z-index: 99;
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    &:hover {
      border: none;
      background-color: transparent;
      box-shadow: none;
      padding: 0;
    }
    &::after {
      content: url(../img/play-gif.png)
    }
  }
  &.playing .play_button {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.5;
  }
  img {
    width: 500px;
  }
}