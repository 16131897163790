.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 94vw;
    flex-direction: column;
}
.topColumn {
    margin-left: auto;
}
.box {
    background-color: #fff;
    width: 713px;
    height: 477px;
    border-radius: 1rem;
    padding: 60px;
    box-shadow: 0px 2px 10px rgba(175, 137, 255, 0.15);
}

.jubelio_logo {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    img {
        width: 103px;
        height: 61px;
    }
}
.content {
    margin-top: 2rem;
}
.title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 2rem;
}
.login_form {
    display: grid;
    gap: 1rem;
}
.login_btn {
    background-color: rgb(64, 166, 64);
    border: none;
    outline: none;
    width: 100%;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 5px;
    width: 180px;
    border-radius: 7px;
    margin: 0 auto;
    color: white;
    cursor: pointer;
}

.whatsappLogo {
  height: 30px;
}

.footerText {
    font-size: 12px !important;
    text-decoration: none !important;
    color: #000 !important;
  }