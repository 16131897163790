// .btn-default.disable {
//   opacity: .75;
// }

// .btn-wrapper {
//   display: flex;
//   .ms-Spinner {
//     margin-right: 10px;
//   }
// }

// .btn-danger {
//   background-color: $danger !important;
//   border: none;
//   color: $textSecondary;
//   &:hover {
//     color: $textSecondary;
//     background-color: $dangerHover !important;
//       }
//   &:active {
//     color: $textSecondary;
//     background-color: $dangerActive !important;
//   }
//   &.disable{
//     opacity: .75;
//   }
// }

// .btn-warning {
//   border: none;
//   background-color: $warning !important;
//   color: $textSecondary;
//   &:hover {
//     color: $textSecondary;
//     background-color: $warningHover !important;
//   }
//   &:active {
//     color: $textSecondary;
//     background-color: $warningActive !important;
//   }
//   &.disable{
//     opacity: .75;
//   }
// }

// .btn-primary {
//   border: none;
//   background-color: $primary !important;
//   color: $textSecondary;
//   &:hover{
//     color: $textSecondary;
//     background-color: $primaryHover !important;
//   }
//   &:active {
//     color: $textSecondary;
//     background-color: $primaryActive !important;
//   }
//   &.disable{
//     opacity: .75;
//   }
// }

// .mdl {
//   .ms-Dialog-main {
//     padding: 10px 20px;
//     position: relative;
//     .modalFooter {
//       position: absolute;
//       display: flex;
//       bottom: 0px;
//       left: 0px;
//       padding: 10px 10px;
//       button {
//         margin: 0 5px;
//       }
//     }
//   }
// }

// .ml-1 {
//   margin-left: 10px;
// }

// Variables
$link-color: #0078d4;
$danger: #f83245;
$dangerActive: #bd081c;
$dangerHover: #c4252a;
$warning: #fbfacf;
$warningActive: #e0c800;
$warningHover: #f0d602;
$primary: #0078d4;
$primaryActive: #005a9e;
$primaryHover: #106ebe;
$textPrimary: #000000;
$textSecondary: #ffffff;
$black: #163a50;

$font-family: 'Nunito Sans', sans-serif;

@mixin font-defaults {
  font-family: $font-family;
}

@mixin heading {
  @include font-defaults;

  font-size: 40px;
  font-weight: bold;
}

.iconModalConfirmation {
  color: $textSecondary;
  font-size: 90px;
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 0;
  border-radius: 50%;
  background-color: #d6d6d6;
}

.btn-outline-danger {
  background: #f83245 !important;
}
