// stylelint-disable declaration-no-important

// Common values
@each $position in $positions {
  .position-#{$position} {
    position: $position !important;
  }
}

// Shorthand

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 25;
}

.absolute-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 25;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.pointer-event-none {
  pointer-events: none;
}

.sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
  }
}
