.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94vw;
  margin: auto;
  padding-bottom: 4vh;
  margin-top: 90px;
}

.logoJubelio {
  padding-top: 35px;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  img {
    width: 100%;
    height: 100%;
  }
}

.textLogo {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  p {
    // width: 639px;
    height: 19px;
    left: 392px;
    top: 110px;

    font-family: 'Nunito Sans', sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 19px !important;

    display: flex;
    align-items: center;
    text-align: center;
  }
}

.onboardingContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.dialogTitle {
  font-family: 'Nunito Sans' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 41px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #163a50 !important;
  margin-bottom: 20px !important;
}

.dialogDescription {
  font-family: 'Nunito Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #163a50 !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.dialogButton {
  justify-content: center !important;
  align-items: center !important;
  padding: 9.5px 26px !important;
  gap: 8px !important;
  min-width: 136px !important;
  min-height: 35px !important;
  background: #4b68ef !important;
  border-radius: 8px !important;
  font-family: 'Nunito Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #ffffff !important;
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  box-shadow: none !important;
}
