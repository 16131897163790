@use '../../../App.scss' as app;
@use '../../../color' as bc;

.step1Container {
  flex-direction: column;
  margin-bottom: 25px;
}

.title {
  height: 27px;
  left: 534px;
  top: 191px;
  font-family: 'Nunito Sans', sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  display: flex;
  align-items: center;
  margin-bottom: 10px !important;
}

.titleDescription {
  height: 19px;
  left: 534px;
  top: 228px;
  font-family: 'Nunito Sans', sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  display: flex;
  align-items: center;
}

.generatlInformation {
  height: 22px;
  font-family: 'Nunito Sans', sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  display: flex;
  align-items: center;
}

.formContainer {
  margin-top: 5px;
}

.inputLabel {
  height: 16px;
  left: 534px;
  top: 342px;
  font-family: 'Nunito Sans', sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  display: flex;
  align-items: center;
  color: #163a50 !important;
}

.inputLabelPopup {
  @extend .inputLabel;
  font-weight: 600 !important;
}

.inputLabelSecondary {
  @extend .inputLabel;
  font-weight: 600 !important;
  color: bc.$secondary !important;
  display: inline;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .inputLabel {
    font-size: 11px !important;
  }
}

.radioDescription {
  padding-left: 24px;
  // left: 555px;
  // top: 623px;

  font-family: 'Nunito Sans', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 22px !important;
  color: #878a8e !important;
}

.uploadImageContainer {
  padding-left: 20px !important;
  height: 13vw;
  min-height: 178px;
  max-height: 290px;
}

.imgContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.imgPreview {
  position: relative;
  background-color: blue;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  top: 7px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.imgButton {
  background-color: #4B68EF;
  color: white;
  border-radius: 10px;
  text-align: center;
  border: none;
  outline: none;
  width: 95%;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.uploadImageTitle {
  font-family: 'Nunito Sans', sans-serif !important ;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  margin-bottom: 9px;
}

.uploadImageRule {
  @extend .uploadImageTitle;
  color: bc.$danger;
}

.modalActionButton {
  font-size: 12px !important;
  width: 99px;
  height: 35px;
  background: #4b68ef;
  padding: 5px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.titleAdress {
  font-family: 'Nunito Sans', sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 30px !important;
  color: #163a50 !important;
}

.dropDownInput {
  div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    input {
      padding: 0 !important;
      height: 2.6vw !important;
    }
  }
}
.generalInformationForm {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}