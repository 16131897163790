$font-family: 'Nunito Sans', sans-serif;
.uppy-Dashboard-AddFiles-title {
  font-size: 14px;
  font-family: $font-family;
  color: #62656f;
}

[data-uppy-drag-drop-supported='true'] .uppy-Dashboard-AddFiles {
  margin: 0px;
  height: calc(100% - 0px);
  border: none;
}

// [data-uppy-num-acquirers='0'] .uppy-Dashboard-AddFiles-title {
//   text-align: left;
//   margin-top: 5px;
// }

// .uppy-Dashboard-inner {
//   background-color: #f5faff;
//   border: none;
//   border-radius: 4px;
// }
