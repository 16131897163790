.rte-container {
  position: absolute;
  margin-left: 10px;
  margin-top: -10px;
  background-color: white;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  z-index: 999;
}
.text-editor {
  font-family: 'Nunito Sans', sans-serif;
  border-radius: 4px;
}

.text-editor.border-error {
  border: 1px solid red;
}
