//  Core

.MuiTooltip-tooltip {
  box-shadow: $box-shadow-sm;
}

.tooltip-lg {
  width: $tooltip-max-width * 1.5;
}

@each $color, $value in $theme-colors {
  .MuiTooltip-tooltip.tooltip-#{$color} {
    background: $value;
    color: color-yiq($value);

    .MuiTooltip-arrow {
      color: $value;
    }
  }
}

.MuiTooltip-popperArrow[data-popper-placement='bottom-end'],
.MuiTooltip-popperArrow[data-popper-placement='bottom'] {
  div {
    .tooltip-custom-arrow {
      top: -7px !important;
    }
  }
}

.MuiTooltip-popperArrow[data-popper-placement='top-end'],
.MuiTooltip-popperArrow[data-popper-placement='top'] {
  div {
    .tooltip-custom-arrow {
      bottom: -7px !important;
    }
  }
}

.tooltip-custom {
  background-color: #fff !important;
  border: 1px solid #e6e8ed !important;
  color: #4a4a4a !important;

  .MuiTooltip-tooltipPlacementBottom .tooltip-custom-arrow {
    bottom: '-7px !important';
  }
}

.tooltip-custom-arrow {
  &::before {
    border: 1px solid #e6e8ed !important;
  }

  color: #fff !important;
  width: 2em !important;
  height: 1.42em !important;
}

.tooltip-title {
  font-size: 12px;
}
