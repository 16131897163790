.errorContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 10px;
    margin-top: 2px;
    & > span {
        font-size: 13px;
        color: #df4d4d;
    }
}