.MuiDataGrid-root {
  font-family: Nunito Sans, sans-serif !important;
  .MuiDataGrid-columnHeaders {
    background-color: #fff !important;
  }

  .Mui-error {
    background-color: red;
  }
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
  outline: none !important;
}
.MuiDataGrid-columnHeaders {
  background-color: rgba(213, 230, 251, 0.53);
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  font-size: 12px;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox:focus {
  outline: none !important;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  // outline: none !important,
}
.MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus {
  // outline: none !important,
}
.MuiDataGrid-renderingZone {
  max-height: none !important;
}
.MuiDataGrid-cell {
  // lineHeight: unset !important,
  max-height: none !important;
  padding: 3px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  // outline: none !important,
  // whiteSpace: normal,
}
.MuiDataGrid-row {
  max-height: none !important;
}
.MuiDataGrid-virtualScrollerContent {
  height: 100% !important;
}

.formattedCellMuiGrid {
  line-height: unset !important;
  font-size: 14px;
}

.editCellInputMuiGrid {
  width: 100% !important;
  height: 100%;

  .MuiFormControl-fullWidth {
    margin: 0px !important;
    height: 100%;
  }
  .MuiInputBase-fullWidth {
    height: 100% !important;
    // border: '0px !important',
  }
  .MuiInputBase-formControl {
    border: none !important;
  }
}

.loadingMuiGrid {
  width: 100%;
  margin-bottom: -10px;
  height: 10px;
}
