@use '../../../App.scss' as app;
@use '../../../color' as bc;

.step6Container {
  flex-direction: column;
  margin-bottom: 25px;
}

.title {
  font-weight: 700;
  font-size: 20px;
}

.subTitle {
  font-weight: 700;
  font-size: 16px;
}

.titleDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 19.1px;
}