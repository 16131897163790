@use 'sass:math';
//  Core

.app-sidebar {
  &--userbox {
    @include border-radius($border-radius-sm);
    padding: $spacer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: (math.div($layout-spacer, 3)) (math.div($layout-spacer, 2)) (math.div($layout-spacer, 5));
    position: relative;
    flex-direction: column;
    text-align: center;
    transition: all 0.5s $app-sidebar-transition;

    .btn-userbox {
      white-space: nowrap;
      box-shadow: none;
    }

    .card-tr-actions {
      position: absolute;
      right: (math.div($spacer, 2));
      top: (math.div($spacer, 3));
      transition: all 0.5s $app-sidebar-transition;
      visibility: visible;
      opacity: 1;
    }

    .avatar-icon-wrapper {
      margin: 0;
      transition: all 0.5s $app-sidebar-transition;

      .badge-circle {
        width: 10px;
        height: 10px;
      }
    }
  }
}

// Themes

@import 'themes/dark';
@import 'themes/light';

// Modifiers

@import 'modifiers/collapsed';
