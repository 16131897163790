@use 'sass:math';
// Tabs

// Variables

$tabs-item-height: 42px;

body {
  .slider-tabs {
    .MuiTabs-flexContainer {
      background: #f6f7f8;
      border-radius: 6px;
      padding: 5px;
    }

    .MuiTab-root {
      color: #163a50;
      z-index: 999;
    }

    .MuiTabs-indicator {
      height: 46px;
      border-radius: 6px;
      bottom: 6px;
      background-color: #fff;
    }

    .MuiTabs-scroller {
      flex: none;
      width: auto;
    }
  }
  // .MuiTabs-root {
  //   min-height: $tabs-item-height;
  //   // height: $tabs-item-height;
  //   overflow: visible !important;

  //   .MuiTabs-scroller {
  //     overflow: visible !important;
  //   }

  //   .MuiTabs-flexContainer {
  //     position: relative;
  //     z-index: 6;
  //   }

  //   .MuiTab-root {
  //     padding: 0 ($spacer * 1.5);
  //     min-height: $tabs-item-height;
  //     height: $tabs-item-height;
  //     transition: $transition-base;
  //     margin-right: (math.div($spacer, 2));

  //     &.MuiTab-font-sm {
  //       font-size: $font-size-sm !important;
  //     }

  //     .MuiTouchRipple-root {
  //       @include border-radius($border-radius-sm !important);
  //     }

  //     &.MuiTab-fullWidth {
  //       margin: 0;
  //       padding: 0 ($spacer);
  //     }
  //   }

  //   .MuiTabs-indicator {
  //     min-height: $tabs-item-height;
  //     height: $tabs-item-height;
  //     opacity: 1;
  //     z-index: 4;
  //     @include border-radius($border-radius-sm);
  //     transition: $transition-base;
  //   }

  //   // Colors

  //   @each $color, $value in $theme-colors {
  //     &.nav-tabs-#{$color} {
  //       .MuiTabs-indicator {
  //         background: $value !important;
  //         box-shadow: 0 16px 26px -10px rgba($value, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12),
  //           0 8px 10px -5px rgba($value, 0.2);
  //         height: 2px !important;
  //         min-height: 2px !important;
  //       }

  //       .MuiTab-root {
  //         font-family: 'Nunito Sans';
  //         font-style: normal;
  //         font-weight: 600;
  //         font-size: 14px;
  //         line-height: 22px;

  //         &:hover {
  //           color: $value;
  //         }

  //         &.Mui-selected {
  //           color: #4B68EF !important;
  //         }
  //       }
  //     }
  //   }
  // }
}

.tabs-bordered {
  position: relative;

  &:after {
    content: '';
    left: 0;
    width: 100%;
    bottom: 0;
    margin: 0;
    position: absolute;
    height: 1px;
    background: $gray-400;
  }
}

.tab-item-wrapper {
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: $transition-base;

  &.active {
    opacity: 1;
    height: auto;
    overflow: auto;
    visibility: visible;
  }
}

.MuiTab-root label {
  cursor: pointer;
}
