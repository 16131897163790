@use 'sass:math';
.dropdownSelector {
  display: inline-flex;
  margin: 0 4px;
  position: relative;
  .dropdown-arrow {
    margin-top: -7px;
    transform: rotate(0deg);
    transition: $transition-base;
    margin-left: 8px;
    height: 16px;
    line-height: 16px;
  }
  .submenu-dropdown {
    position: absolute;
    top: 100%;
    // left: 50%;
    width: 280px;
    // margin-left: -140px;
    visibility: hidden;
    opacity: 0;
    padding-top: 15px;
    transition: $transition-base;
    z-index: 20;
    transform: translateX(-18px) translateY(-18px) scale(0.75);

    &--xs {
      width: unset;
    }

    &--xs > div {
      min-width: 0;
    }

    &--sm {
      width: 200px;
      margin-left: -100px;
    }

    &--lg {
      width: 420px;
      margin-left: -210px;
    }

    &--xl {
      width: 640px;
      margin-left: -320px;
    }

    &--xxl {
      width: 940px;
      margin-left: -470px;
    }
  }

  & > a {
    transition: $transition-base;
    padding: (math.div($spacer, 1.5)) ($spacer * 1.5);
    display: flex;
    align-items: center;
  }

  &:hover {
    & > a {
      & > .dropdown-arrow {
        margin-top: 0px;
        transform: rotate(-180deg);
      }
    }

    & > .submenu-dropdown {
      opacity: 1;
      transform: translateX(0px) translateY(0px) scale(1);
      visibility: visible;
    }
  }
}
