//  Core

.MuiInputBase-root:not(.MuiInput-underline) {
  transition: $transition-base;
  // padding: 5px 15px;
  // padding-right: 0;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;

  // &.Mui-focused {
  //   box-shadow: $input-focus-box-shadow;
  // }

  // ::placeholder {
  //   color: #bcbcbc !important;
  //   opacity: 1;
  // }
}

body {
  .MuiTextField-root.text-white-50 {
    .MuiInputBase-root,
    .MuiInputLabel-root {
      color: rgba($white, 0.8);
    }

    .MuiOutlinedInput-root,
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba($white, 0.5);

      &:hover {
        border-color: rgba($white, 0.8);
      }
    }
  }

  .MuiChip-root {
    background: #4b68ef;
    // color: $black;
    // height: auto;
    // padding: 2px 4px !important;
    font-size: 12px;
    padding: 8px;
    border-radius: 28px;

    .MuiChip-deleteIcon {
      // color: #afb2b7;
      width: 16px;
    }

    &.Mui-disabled {
      opacity: 0.65;
    }
  }
}
.MuiInputBase-root,
.MuiAutocomplete-root {
  .MuiFormLabel-root {
    margin-left: 11px;
    background: white;
    z-index: 2;
    padding: 0 8px;
  }
  .MuiInput-underline:before {
    border-bottom: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .MuiAutocomplete-input {
    padding: 5px 0;
    padding-bottom: 8px;
  }
  .MuiButtonBase-root {
    // padding: 5px;
    // padding-top: 0;
  }
  .MuiInputBase-root {
    // border: 1px solid;
    border-radius: 8px;
    margin-top: 0px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 11px;
    border-color: rgba(0, 0, 0, 0.23);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  .MuiInputLabel-formControl {
    transform: translate(0, 20px) scale(1);
    top: -8px;
  }
  .MuiInputLabel-shrink {
    transform: translate(0, 2.5px) scale(0.75);
    transform-origin: top left;
  }
}

.MuiFormHelperText-contained {
  margin-left: 0px !important;
}

.dropdown-labeled {
  .MuiInputBase-root {
    padding-top: 8px !important;
  }
}
