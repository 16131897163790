//  Core

.badge {
  text-transform: uppercase;
  height: 22px;
  line-height: 12px;
  text-align: center;
}

@mixin badge-neutral-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
}

@each $color, $value in $theme-colors-neutral {
  @include badge-neutral-variant('.badge-neutral-#{$color}', $value);
}
// Dots
.badge-neutral-second {
  background-color: #f1f1f3 !important;
}

.badge-neutral-green {
  background-color: rgba(76, 175, 80, 0.2) !important;
  color: #4caf50 !important;
}

.badge-neutral-orange {
  background-color: rgba(253, 205, 49, 0.2) !important;
  color: #ff9800 !important;
}

.badge {
  &.badge-circle-inner,
  &.badge-circle {
    text-indent: -999rem;
    @include border-radius(100%);
    height: 8px;
    width: 8px;
    padding: 0;
  }

  &.badge-circle-inner {
    width: 16px;
    height: 16px;
    position: relative;

    &::after {
      content: '';
      background: $white;
      width: 8px;
      height: 8px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -4px 0 0 -4px;
      @include border-radius(100%);
    }
  }
}

// Dots positions

.badge-wrapper {
  position: relative;
}

.badge-position {
  position: absolute !important;

  &--top-right {
    &.badge-circle {
      right: -5px;
      top: -5px;
    }

    &.badge-circle-inner {
      right: -8px;
      top: -8px;
    }
  }

  &--top-center {
    &.badge-circle {
      left: 50%;
      margin-left: -5px;
      top: -5px;
    }

    &.badge-circle-inner {
      left: 50%;
      margin-left: -8px;
      top: -8px;
    }
  }

  &--top-left {
    &.badge-circle {
      left: -5px;
      top: -5px;
    }

    &.badge-circle-inner {
      left: -8px;
      top: -8px;
    }
  }

  &--bottom-right {
    &.badge-circle {
      right: -5px;
      bottom: -5px;
      top: auto;
    }

    &.badge-circle-inner {
      right: -8px;
      bottom: -8px;
      top: auto;
    }
  }

  &--bottom-center {
    &.badge-circle {
      left: 50%;
      margin-left: -5px;
      bottom: -5px;
      top: auto;
    }

    &.badge-circle-inner {
      left: 50%;
      margin-left: -8px;
      bottom: -8px;
      top: auto;
    }
  }

  &--bottom-left {
    &.badge-circle {
      left: -5px;
      bottom: -5px;
      top: auto;
    }

    &.badge-circle-inner {
      left: -8px;
      bottom: -8px;
      top: auto;
    }
  }
}
