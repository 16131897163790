@use './_color' as bc;

html {
  height: 100%;
  display: table;
  margin: auto;
  min-width: 1200px;
  max-width: 1400px;
  background-color: #f1f9ff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  height: 100%;
  padding: 0 !important;
  display: table-cell;
  vertical-align: middle;
  width: 100vw;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #163a50;
}

#root {
  min-height: 100vh;
}

.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94vw;
  margin: auto;
  padding-bottom: 4vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: bc.$bg-color;
}

p, button {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #163a50;
}

.onboarding-vertical-stepper {
  display: flex;
  flex-direction: row;
}

.onboarding-content {
  display: flex;
  flex-direction: row;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.contact {
  align-items: center;
}
.item-footer {
  display: flex;
  align-items: center;
  margin-right: 60px;
}

.button-container {
  position: relative;
}

.requiredField::after {
  content: '*';
  color: #df4d4d;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

// .imageWrapper img {
//   max-width: 255px;
// }