.inputField {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 7.5px 12px !important;
  gap: 10px;
  width: -webkit-fill-available;
  height: 2.6vw !important;
  background: #ffffff;
  border-radius: 8px;
  max-height: 35px;
}
