// Material UI Helpers
body {
  .MuiButtonBase-root {
    min-width: 0;
    outline: none !important;
  }

  .MuiButton-root {
    line-height: initial;

    &:focus {
      outline: none;
    }
  }

  // .MuiPaper-root {
  //   color: $body-color;
  // }
}

.MuiGrid-item,
.MuiPaper-root {
  position: relative;
}
@include media-breakpoint-down(lg) {
  .MuiGrid-item {
    width: 100%;
  }
}

.MuiCard-root {
  transition: $transition-base;
}

a.MuiButton-outlined:hover {
  color: initial;
}

.min-w-auto {
  min-width: 5px !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  outline: none !important;
}

.MuiButton-root.btn-inverse:hover {
  background-color: rgba(255, 255, 255, 0.07);
}

// Custom spacing

.mb-spacing-6 {
  margin-bottom: 24px;
}

.mb-spacing-6-x2 {
  margin-bottom: 48px;
}

// Forms

// body {
//   .MuiOutlinedInput-notchedOutline {
//     transition: $transition-base;
//     border-color: #d4d5d6 !important;
//   }

//   .MuiOutlinedInput-root:not(.no-hover):hover {
//     .MuiOutlinedInput-notchedOutline {
//       border-color: $primary;
//     }
//   }
// }

.RichTextEditor__editor___1QqIU {
  font-size: 14px;
}

// responsive
@media (max-width: 1200px) {
  .MuiContainer-root {
    // padding: 0px !important;

    .MuiBreadcrumbs-root {
      padding: 0 20px;
    }
  }
}
